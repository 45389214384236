import { Paginated } from '../../types';
import { get, patch } from './methods';
import { ExcelParams } from './Payments';

export interface ITip {
	id: number;
	email: string;
	tariff: string;
	usedTips: number;
	freeTips: number;
	restPaidTips: number;
}

export const TipsApi = {
	getAll: get<Paginated<ITip>>('/tips'),
	update: patch('/admin/users/tips'),
	getExcel: get<string, ExcelParams>('/admin/export-requests-history'),
};
