import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useMemo } from 'react';
import AppTable from '../../../components/tables/AppTable/AppTable';
import { tipsStoreContext } from '../../../stores/TiptsStore';

const columns = [
	{
		title: 'Email',
		dataIndex: 'email',
		key: 'email',
		sorter: true,
	},
	{
		title: 'Paid count',
		key: 'balance.paidAttemptsCount',
		sorter: true,
		render: ({ balance }) => {
			return balance?.paidAttemptsCount || 0;
		},
	},
	{
		title: 'Free attempts',
		key: 'balance.freeAttemptsCount',
		sorter: true,
		render: ({ balance }) => {
			return balance?.freeAttemptsCount || 0;
		},
	},
];

interface Props {}

const RequestsTable = (props: Props) => {
	const { tableData, tableSelected, setTableSelected } = useContext(tipsStoreContext);

	useEffect(() => {
		tableData.fetch();
	}, []);

	const handleChange = (pagination, filters, sorter) => {
		if (!sorter?.field) {
			sorter.field = sorter.columnKey;
		}
		tableData.setPage(pagination.current);
		tableData.setSorter(sorter);
	};

	const rowSelection = useMemo(() => {
		return {
			selectedRowKeys: tableSelected,
			onChange: (keys) => setTableSelected(keys),
		};
	}, [tableSelected]);

	return (
		<AppTable
			key={tableData.pagination.total + '' + tableData.pagination.page}
			pagination={{ ...tableData.pagination }}
			columns={columns}
			dataSource={tableData.dataSource}
			onChange={handleChange}
			loading={tableData.loading}
			rowSelection={rowSelection}
		/>
	);
};

export default observer(RequestsTable);
