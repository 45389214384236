import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';
import { TipsApi } from '../services/api/Tip';
import { IUser, UsersApi } from '../services/api/Users';
import { Roles, TABLE_PER_PAGE } from '../utils/constants';
import { createTableData, createTableDataDefault } from '../utils/createTableData';

class TipsStore {
	page: number = 1;
	tableSelected: number[] = [];

	tableData = createTableDataDefault<IUser>();

	constructor() {
		makeAutoObservable(this);
		createTableData(this, this.query);
	}

	query = (tableParams = {}) => {
		const params = {
			role: Roles.user,
		};

		return UsersApi.getAll({ ...tableParams, ...params });
	};

	updateTips = async ({ count, type, field }) => {
		this.tableData.loading = true;

		try {
			await TipsApi.update({ tips: count, userIds: this.tableSelected });

			this.tableData.dataSource = this.tableData.dataSource.map((user) => {
				const newUser = { ...user };

				if (this.tableSelected.includes(user.id)) {
					let rest = newUser.balance.freeAttemptsCount - count;

					newUser.balance.freeAttemptsCount = Math.max(0, rest);

					if (newUser.balance.freeAttemptsCount === 0) {
						newUser.balance.paidAttemptsCount += rest;
					}
				}

				return newUser;
			});

			this.tableSelected = [];
		} catch (error) {
			console.log('error: ', error);
		} finally {
			this.tableData.loading = false;
		}
	};

	setTableSelected = (selected) => {
		this.tableSelected = selected;
	};
}

export const tipsStore = new TipsStore();
export const tipsStoreContext = createContext(tipsStore);
