import { Button, InputNumber } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import DownloadDrawer from '../../components/DownloadDrawer';
import withPrivateRoute from '../../hocs/withPrivateRoute';
import RequestsTable from '../../modules/Admin/Requests/RequestsTable';
import { TipsApi } from '../../services/api/Tip';
import { tipsStoreContext } from '../../stores/TiptsStore';
import { Roles } from '../../utils/constants';

const fieldsToUpdate = [
	{
		name: 'Used requests',
		value: 'usedTips',
	},
	{
		name: 'Free requests',
		value: 'freeTips',
	},
	{
		name: 'Rest paid requests',
		value: 'restPaidTips',
	},
];

const typeUpdates = [
	{
		name: 'Set exact',
		value: 'exact',
	},
	{
		name: 'Add requests',
		value: 'add',
	},
];

interface Props {}

const UsersTips = (props: Props) => {
	const { tableSelected, updateTips } = useContext(tipsStoreContext);
	const [count, setCount] = useState(1);
	const [typeUpdate, setTypeUpdate] = useState(typeUpdates[1]);
	const [fieldUpdate, setFieldUpdate] = useState(fieldsToUpdate[0]);

	const handleUpdateCount = () => {
		updateTips({
			count,
			field: fieldUpdate.value,
			type: typeUpdate.value,
		});
	};

	return (
		<>
			<Helmet>
				<title>Requests</title>
			</Helmet>
			<div className="page">
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<h1 className="page__title">Requests</h1>
					<DownloadDrawer api={TipsApi.getExcel} />
				</div>

				<div className="flex gap-4 mb-4">
					<InputNumber
						value={count}
						onChange={(value) => setCount(value)}
						style={{ width: 120 }}
						min={1}
						placeholder="Count"
						disabled={!tableSelected.length}
					/>
					<Button onClick={handleUpdateCount} type="primary" disabled={!tableSelected.length}>
						Add requests
					</Button>
				</div>
				<RequestsTable />
			</div>
		</>
	);
};

export default withPrivateRoute(observer(UsersTips), [Roles.admin]);
